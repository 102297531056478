import React, {useContext, createContext, useState} from 'react';
import SimpleAssistantAPI from "../../common/handlers/api-handlers/SimpleAssistantAPI";
import {useError} from "../errors/ErrorProvide";
import CartAPI from "../handlers/api-handlers/CartAPI";

const CartContext = createContext();

export const useCartContext = () => useContext(CartContext);

export const CartProvider = ({children}) => {
    const [cartItems, setCartItems] = useState({});
    const [cartAmount, setCartAmount] = useState(0);
    const [cartLoading, setCartLoading] = useState(true);
    const [cartPrice, setCartPrice] = useState("0,00");
    const [cartUpdated, setCartUpdated] = useState(false);
    const [requestPaymentAllowed, setRequestPaymentAllowed] = useState(false);

    const [requestState, setRequestState] = useState(null);
    const [requestHistory, setRequestHistory] = useState(null);

    const API = new SimpleAssistantAPI();
    const CAPI = new CartAPI();
    const {showError} = useError();

    const getCart = async () => {
        setCartLoading(true);
        try {
            const [success, response] = await CAPI.getCart();
            if (success) {
                const data = response.data;
                setCartItems(data.cart);
                setCartAmount(data.amount);
                setCartPrice(data.full_price);
            } else {
                showError(response);
                setCartItems([]);
                setCartAmount(0);
                setCartPrice("0,00");
            }
        } finally {
            setCartLoading(false);
        }
    }

    const getCartAmount = async () => {
        getCart().then(() => {
            return cartAmount;
        });
    }

    const addSimpleAssistantToCart = async (data) => {
        setCartLoading(true);
        setCartUpdated(false);

        if (!data) {
            showError('No data provided');
            setCartLoading(false);
            return false;
        }

        const person_id = data.person_id;

        const [success, response] = await API.addToCart({personId: person_id, data: data});
        if (success) {
            setCartItems(response);
            setCartAmount(response.data.amount);
            setCartPrice(response.data.full_price);
            setCartLoading(false);
            return true;
        } else {
            showError(response.msg);
            setCartLoading(false);
            return false;
        }
    }

    const removeFromCart = async ({cart_item_id, person_id}) => {
        setCartLoading(true);

        if (!cart_item_id || !person_id) {
            showError('No data provided');
            return;
        }

        const data = {
            "cart_item_id": cart_item_id,
            "person_id": person_id
        }

        const [success, response] = await CAPI.removeFromCart(data);
        if (success) {
            setTimeout(() => {
                getCart();
            }, 1000);
        } else {
            showError(response.msg);
            setCartLoading(false);
        }
    }

    const requestStateUpdate = (state) => {
        setRequestState(state);
    }

    const requestHistoryUpdate = (history) => {
        setRequestHistory(history);
    }

    const checkRequestState = async (processId) => {
        requestStateUpdate(null);
        requestHistoryUpdate(null);
        const [success, response] = await CAPI.getRequestState(processId);
        if (success) {
            requestStateUpdate(response.data.active_state);
            requestHistoryUpdate(response.data.states);
            if (response.data.payment_allowed !== undefined && response.data.payment_allowed !== null) {
                setRequestPaymentAllowed(response.data.payment_allowed);
            }
        } else {
            showError(response.msg);
        }
    }

    const cleanUpSession = async () => {
        const [success, response] = await CAPI.cleanUpSession();
        if (success) {
            getCartAmount();
            sessionStorage.removeItem('address')
            localStorage.removeItem('address')
        }
    }

    return (
        <CartContext.Provider
            value={{
                // bla
                cartAmount,
                getCartAmount,
                cartLoading,
                addSimpleAssistantToCart,
                getCart,
                cartPrice,
                cartItems,
                removeFromCart,
                cartUpdated,
                setCartUpdated,
                checkRequestState,
                requestState,
                requestStateUpdate,
                requestHistory,
                cleanUpSession,
                requestPaymentAllowed
        }}>
            {children}
        </CartContext.Provider>
    );
}