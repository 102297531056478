import React, {useEffect} from 'react';
import {Box, Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";


function Disciplines({
    disciplines,
    selectedDisciplines,
    setSelectedDisciplines
                     }) {

    const { t } = useTranslation();

    useEffect(() => {

    }, [selectedDisciplines]);

    const handleDisciplineChange = (e) => {
        const value = e.target.value;
        if (selectedDisciplines === null || selectedDisciplines === undefined) {
            setSelectedDisciplines([value]);
        } else {
            if (selectedDisciplines.includes(value)) {
                setSelectedDisciplines(selectedDisciplines.filter((discipline) => discipline !== value));
            } else {
                setSelectedDisciplines([...selectedDisciplines, value]);
            }
        }
    }

    if (disciplines === null || disciplines === undefined || disciplines.length === 0) {
        return null;
    }

    return (
            <Box
                sx={{
                    p: 1,
                    width: '100%'
                }}
            >
                <Typography>{t('simple-assistant.additional-information.disciplines')}</Typography>
                <small>{t('simple-assistant.additional-information.disciplines-info')}</small>
                {
                    disciplines.map((discipline, index) => {
                        let checked = false;
                        if (selectedDisciplines !== null && selectedDisciplines !== undefined) {
                            checked = selectedDisciplines.includes(discipline.id);
                        }
                        return (

                            <Box
                                key={'discipline-' + discipline.id}
                                sx={{
                                    pl: 1,
                                }}
                            >
                                <FormControlLabel
                                    control={<Checkbox/>}
                                    label={discipline.name}
                                    id={'discipline-' + discipline.id}
                                    name={'discipline-' + discipline.id}
                                    value={discipline.id}
                                    onChange={handleDisciplineChange}
                                    checked={checked}
                                />
                                <FormHelperText>{discipline.description}</FormHelperText>
                            </Box>
                        )
                    })
                }
            </Box>
    );
}

export default Disciplines;