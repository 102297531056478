import React, {useEffect} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box, Checkbox,
    FormControl,
    FormControlLabel,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useMobileViewContext} from "../../globals/MobileViewContext";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ProductSelect from "./ProductSelect";
import {CalendarSelectionProvider} from "../../globals/CalendarContext";
import {useCartContext} from "../../../../../common/providers/CartContext";
import {useProductContext} from "../../globals/ProductContext";

const initContentMargin = '10px';

const initAlignmentProducts = {
    display: 'flex',
    flexDirection: 'row',
    gap: '0',
    width: '100%'
}

function CategoryAccordion({itemData}) {

    const {i18n, t} = useTranslation();

    let currentLanguage = i18n.language;
    let fallbackLanguage = i18n.options.fallbackLng[0];

    const [contentMargin, setContentMargin] = React.useState(initContentMargin);
    const {viewType} = useMobileViewContext();
    const [childredSelect, setChildrenSelect] = React.useState([]);
    const [selectedChild, setSelectedChild] = React.useState(null);
    const [selectedProducts, setSelectedProducts] = React.useState({})
    const [alignmentProducts, setAlignmentProducts] = React.useState(initAlignmentProducts);
    const {cartUpdated, setCartUpdated} = useCartContext();
    const {activeProduct, setActiveProduct} = useProductContext();
    const [expanded, setExpanded] = React.useState(false);

    useEffect(() => {
        let newContentMargin = initContentMargin;
        let newAlignmentProducts = initAlignmentProducts;

        if (viewType === 'mobile' || viewType === 'tablet') {
            newContentMargin = '5px';
            newAlignmentProducts.flexDirection = 'column';
            newAlignmentProducts.gap = '10px';
        } else {
            newContentMargin = initContentMargin;
            newAlignmentProducts.flexDirection = 'row';
            newAlignmentProducts.gap = '0';
        }


        setContentMargin(newContentMargin);
        setAlignmentProducts(newAlignmentProducts);

    }, [viewType]);

    useEffect(() => {
        if (itemData.children !== undefined) {
            const newChildrenSelect = itemData.children.map((child) => ({
                ...child,
                selected: child.selected || false // Ensure 'selected' is always defined
            }));
            setChildrenSelect(newChildrenSelect);
        }

        // setCleanUp();
    }, [itemData.children]);

    useEffect(() => {
        if (cartUpdated) {
            setExpanded(false);
            setCartUpdated(false);
            setChildrenSelect(prevChildren =>
                prevChildren.map(child => ({
                    ...child,
                    selected: false
                }))
            );
        }
    }, [cartUpdated]);

    useEffect(() => {
        if (activeProduct !== null && selectedChild !== null) {
            if (activeProduct !== selectedChild.id) {
                setSelectedChild(null);
                setSelectedProducts({});
                setChildrenSelect(prevChildren =>
                    prevChildren.map(child => ({
                        ...child,
                        selected: child.id === activeProduct
                    }))
                );
            }
        }
        // console.log('activeProduct', activeProduct);
        // console.log('selectedChild', selectedChild)
    }, [activeProduct]);


    const handleSelect = (event) => {
        const selectedId = event.target.value.split("-")[1];
        let isSelected = event.target.checked;
        setChildrenSelect(prevChildren =>
            prevChildren.map(child => ({
                ...child,
                selected: child.id === selectedId ? !child.selected : false
            }))
        );
        handleSelectForProductSelection(selectedId, isSelected);
    };

    const handleSelectForProductSelection = (selectedId, isSelected) => {

        if (!isSelected) {
            setSelectedProducts({})
            setSelectedChild(null)
            return;
        }

        let sC = null;
        childredSelect.map(function (child) {
            if (selectedId === child.id) {
                sC = child;
            }
        });

        if (sC !== null && sC.products !== undefined) {
            setSelectedProducts(sC.products)
            setSelectedChild(sC)
            setActiveProduct(sC.id);
        }

    }


    return (
        <Accordion
            key={itemData.id}
            sx={{ width: '100%' }}
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
        >
            <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{
                    transition: '0.3s',
                    backgroundColor: '#f9f9f9',
                    '&.Mui-expanded': {
                        borderBottom: '1px solid #f0f0f0',
                    },
                    ':hover': {
                        cursor: 'pointer',
                        backgroundColor: '#f0f0f0'
                    }
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        gap: '3px'
                    }}
                >
                    <Box>{
                        (itemData.name[currentLanguage] !== undefined) ? itemData.name[currentLanguage] : itemData.name[fallbackLanguage]
                    }</Box>
                    {
                        (itemData["amount_inner"] !== undefined)
                            ? <Box>({itemData["amount_inner"]})</Box>
                            : <></>
                    }
                </Box>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    paddingX: contentMargin
                }}
            >
                <p dangerouslySetInnerHTML={{__html: (itemData.description[currentLanguage] !== undefined) ? itemData.description[currentLanguage] : itemData.description[fallbackLanguage] }}></p>
                {
                    (itemData.display.img !== undefined)
                        ? (
                            <img
                                src={itemData.display.img.src}
                                alt={itemData.display.img.alt}
                                style={{
                                    maxWidth: "200px",
                                    maxHeight: "200px",
                                    objectFit: itemData.display.img["object-fit"],
                                }}
                            />
                        )
                        : <></>
                }
                <Box
                    sx={alignmentProducts}
                >
                    {
                        (itemData.as_product_selector !== undefined && itemData.as_product_selector === true)
                        ?
                            <>
                                <FormControl sx={{
                                    maxWidth: '400px',
                                    width: '100%'
                                }}>
                                    {
                                        childredSelect.map((child) => {
                                            const name = itemData.id + "-child";
                                            const value = itemData.id + "-" + child.id;
                                            return (
                                                <FormControlLabel
                                                    control={<Checkbox/>}
                                                    label={child.name[currentLanguage]}
                                                    id={name}
                                                    key={child.id}
                                                    name={name}
                                                    value={value}
                                                    onChange={handleSelect}
                                                    checked={child.selected || false}
                                                />
                                            )
                                        })
                                    }
                                </FormControl>
                                <CalendarSelectionProvider key={selectedChild ? selectedChild.id : "default"}>
                                    <ProductSelect selectedChild={selectedChild} products={selectedProducts} currentLanguage={currentLanguage} itemData={itemData}/>
                                </CalendarSelectionProvider>
                            </>
                        : <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    my: 3
                                }}
                            >
                                {
                                    childredSelect.map((innerItem, index) => {
                                        return (<CategoryAccordion itemData={innerItem} key={innerItem.id} />)
                                    })
                                }
                        </Box>
                    }
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}

export default CategoryAccordion;