import React from 'react';
import {
    Box, Button, Checkbox,
    CircularProgress,
    FormControl, FormControlLabel,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import ProductCalendar from "../accordion/product/ProductCalendar";
import {useError} from "../../../../../common/errors/ErrorProvide";
import SimpleAssistantAPI from "../../../../../common/handlers/api-handlers/SimpleAssistantAPI";
import {useCalendarSelectionContext} from "../../globals/CalendarContext";
import {useCartContext} from "../../../../../common/providers/CartContext";
import {useTranslation} from "react-i18next";
import {usePersonContext} from "../../globals/PersonContext";
import {useMobileViewContext} from "../../globals/MobileViewContext";
import ContinueAfterAddCart from "./addOns/ContinueAfterAddCart";
import {PrivateCourseAdditionalPersonSelection, PrivateCourseTimeSelection} from "./addOns/PrivateCourseAddOns";
import RequiredFields from "./addOns/RequiredFields";
import CrossSellingProduct from "./addOns/CrossSellingProduct";
import Disciplines from "./addOns/Disciplines";
import {LoadTemplateFile} from "../../../TemplateLoader";

const initBoxSettings = {
    display: 'flex',
    flexDirection: 'row',
    gap: '50px',
    width: '100%',
}

function ProductSelect({selectedChild, products, currentLanguage, itemData}) {
    const [contentData, setContentData] = React.useState(null);
    const [apiLoading, setApiLoading] = React.useState(false);
    const [selectedProductId, setSelectedProductId] = React.useState('');
    const [crossSellingSelected, setCrossSellingSelected] = React.useState([]);
    const [selectedPickupLocation, setSelectedPickupLocation] = React.useState('');
    const [additionalProducts, setAdditionalProducts] = React.useState([]);
    const [boxSettings, setBoxSettings] = React.useState(initBoxSettings);
    const API = new SimpleAssistantAPI();
    const isMobile = false;
    const [template, setTemplate] = React.useState(null);
    const {t} = useTranslation();
    const {showError} = useError();
    const {viewType} = useMobileViewContext();
    const [openContinueAfterAddCart, setOpenContinueAfterAddCart] = React.useState(false);

    const [crossSellingContinueAfterAddCart, setCrossSellingContinueAfterAddCart] = React.useState([]);
    const [additionalProductsContinueAfterAddCart, setAdditionalProductsContinueAfterAddCart] = React.useState([]);

    // ------- Pre Price and Cart START  -------
    const [preCalculatedPrice, setPreCalculatedPrice] = React.useState(t("simple-assistant.errors.price-calculation.cannot-calculate-price"));
    const {selectedDates, putSelectedDates, setCleanUp} = useCalendarSelectionContext();
    const {addSimpleAssistantToCart, setCartUpdated} = useCartContext();
    const {activePerson} = usePersonContext();
    const [priceIsLoading, setPriceIsLoading] = React.useState(false)

    // ------- Time Selection for Private Courses ----
    const [timeSelection, setTimeSelection] = React.useState(null);
    const [timeSelectionLoading, setTimeSelectionLoading] = React.useState(false);
    const [selectedTimes, setSelectedTimes] = React.useState([]);
    // ------- Time Selection for Private Courses ----

    // ------- Additional Persons for Private Course ----
    const [useAdditionalPersons, setUseAdditionalPersons] = React.useState(false);
    const [selectedAdditionalPersons, setSelectedAdditionalPersons] = React.useState([]);
    // ------- Additional Persons for Private Course ----

    // ------- Required Fields which can be variable ----
    const [requiredFields, setRequiredFields] = React.useState([]);
    const [requiredFieldsData, setRequiredFieldsData] = React.useState({});
    // ------- Required Fields which can be variable ----

    // ------- Disciplines for Private Courses ------
    const [disciplines, setDisciplines] = React.useState([]);
    const [selectedDisciplines, setSelectedDisciplines] = React.useState([]);
    // ------- Disciplines for Private Courses ------


    const handleCartAdd = async () => {
        if (selectedDates === null || selectedDates === undefined) {
            showError(t("simple-assistant.errors.price-calculation.no-dates-selected"));
            return;
        }

        if (selectedDates.start === undefined || selectedDates.end === undefined) {
            showError(t("simple-assistant.errors.price-calculation.no-dates-selected"));
            return;
        }

        const [prepared, data] = preparePrCalculationAndAddToCartData();

        // console.log(data, 'ADD TO CART DATA')

        if (contentData.product.locations !== undefined &&
            contentData.product.locations.length !== null &&
            contentData.product.locations.length > 0)
        {
            if (selectedPickupLocation === '' || selectedPickupLocation === null) {
                setOpenContinueAfterAddCart(false);
                showError(t("simple-assistant.errors.price-calculation.no-pickup-location-selected"));
                return;
            }
            data.pickup_location_id = selectedPickupLocation;
        }

        if (timeSelection !== null && timeSelection.length > 0) {
            let newSelectedTimes = [];
            let failed = false;
            selectedTimes.map((item) => {
                if (item.time_id === '') {
                    setOpenContinueAfterAddCart(false);
                    failed = true;
                }
                newSelectedTimes.push(item);
            })
            data.times = newSelectedTimes;

            if (failed) {
                showError(t("simple-assistant.errors.no-time-selected"));
                return;
            }
        }

        if (requiredFieldsData !== null && requiredFieldsData !== undefined) {
            let newRequiredFieldsData = {};
            let failed = false;
            requiredFields.map((field) => {
                if (requiredFieldsData[field.name] !== undefined && requiredFieldsData[field.name] !== null) {
                    newRequiredFieldsData[field.name] = requiredFieldsData[field.name];

                    if (field.required && (requiredFieldsData[field.name] === '' || requiredFieldsData[field.name] === null)) {
                        failed = true;
                    }
                }
            })

            if (failed) {
                showError(t("simple-assistant.errors.notAllFieldsFilled"));

                return;
            }
        }

        if (disciplines !== null && disciplines.length > 0) {
            if (selectedDisciplines !== null && selectedDisciplines.length > 0) {
                data.disciplines = selectedDisciplines;
            } else {
                showError(t("simple-assistant.errors.no-disciplines-selected"));
                return;
            }
        }

        if (!prepared) {
            return;
        }

        await addSimpleAssistantToCart(data).then((success) => {
            // clean up here and show success message
            setOpenContinueAfterAddCart(false);
            putSelectedDates(null);

            if (success) {
                setCartUpdated(true);
                setCleanUp(true);
                setSelectedProductId('');
                cleanAdditionalProducts();
                cleanCrossSelling();
                setTimeout(() => {
                    setCartUpdated(false);
                }, 1000);
            }
        })
    }

    const cleanAdditionalProducts = () => {
        setAdditionalProducts(prevAdditionalProducts =>
            prevAdditionalProducts.map((item) => {
                return {
                    id: item.id,
                    name: item.name,
                    description: item.description,
                    selected: false
                }
            })
        );

        if (additionalProductsContinueAfterAddCart !== null && additionalProductsContinueAfterAddCart.length > 0) {
            setAdditionalProductsContinueAfterAddCart(prevAdditionalProducts =>
                prevAdditionalProducts.map((item) => {
                    return {
                        id: item.id,
                        name: item.name,
                        description: item.description,
                        selected: false
                    }
                })
            );
        }
    }

    const cleanCrossSelling = () => {
        setCrossSellingSelected(prevCrossSellingSelected =>
            prevCrossSellingSelected.map((item) => {
                return {
                    id: item.id,
                    selected: false,
                    loading: false,
                    fields: null,
                    additional_products: null,
                    data: null,
                }
            })
        );

        if (crossSellingContinueAfterAddCart !== null && crossSellingContinueAfterAddCart.length > 0) {
            setCrossSellingContinueAfterAddCart(prevCrossSellingSelected =>
                prevCrossSellingSelected.map((item) => {
                    return {
                        id: item.id,
                        selected: false,
                        loading: false,
                        fields: null
                    }
                })
            );
        }
    }


    const productAddToCart = async () => {
        if (selectedDates === null || selectedDates === undefined) {
            showError(t("simple-assistant.errors.price-calculation.no-dates-selected"));
            return;
        }

        if (selectedDates.start === undefined || selectedDates.end === undefined) {
            showError(t("simple-assistant.errors.price-calculation.no-dates-selected"));
            return;
        }

        const [prepared, data] = preparePrCalculationAndAddToCartData();

        if (!prepared) {
            return;
        }

        // console.log(template)

        if (template !== null &&
            template.cart !== undefined &&
            template.cart !== null &&
            template.cart.afterAddCartSelection !== undefined &&
            template.cart.afterAddCartSelection !== null
        )
        {
            // console.log('TEMPLATE.CART: ', template.cart);

            if (template.cart.afterAddCartSelection === true) {
                handleContinueAfterAddCart();
            } else {
                handleCartAdd();
            }
        } else {
            // console.log('NO TEMPLATE.CART');
            handleContinueAfterAddCart();
        }
    }

    // ------- Continue After Add Cart START --------
    /*
    These functions are used to show the customer a dialog after clicking the "Add to Cart"
    button. The dialog will show the customer cross selling items and additional products,
    if available and not selected yet.
    The customer can then select these items and add them to the cart as well.

    The actual function to add the items to the cart is called after the customer has
    selected the items in the dialog and clicked in the dialog on the "Add to Cart" button.
    Or the customer can click on the "Continue Shopping" button to close the dialog,
    without adding the items to the cart. But the item that was selected before the
    dialog was opened will be added to the cart.
     */

    const handleContinueAfterAddCart = () => {
        if (contentData !== null && contentData.product !== undefined) {
            // Cross selling items
            let newTMPCrossSellingContinueAfterAddCart = [];
            let newCrossSellingContinueAfterAddCart = [];
            if (crossSellingSelected !== null && crossSellingSelected.length > 0) {
                crossSellingSelected.map((item) => {
                    if (!item.selected) {
                        newTMPCrossSellingContinueAfterAddCart.push(item);
                    }
                });
            }

            if (contentData.product['cross-selling'] !== null && contentData.product['cross-selling'] !== undefined) {
                contentData.product['cross-selling'].map((item) => {
                    // find the item in the temporary array
                    const selectedItem = newTMPCrossSellingContinueAfterAddCart.find((element) => element.id === item.product_id);
                    if (selectedItem !== undefined) {
                        newCrossSellingContinueAfterAddCart.push(item);
                    }
                });
            }

            setCrossSellingContinueAfterAddCart(newCrossSellingContinueAfterAddCart);

            // Additional products
            let newTMPAdditionalProductsContinueAfterAddCart = [];
            if (additionalProducts !== null && additionalProducts.length > 0) {
                additionalProducts.map((item) => {
                    if (!item.selected) {
                        newTMPAdditionalProductsContinueAfterAddCart.push(item);
                    }
                });

                setAdditionalProductsContinueAfterAddCart(newTMPAdditionalProductsContinueAfterAddCart);
            }

            if (newTMPAdditionalProductsContinueAfterAddCart.length === 0 && newCrossSellingContinueAfterAddCart.length === 0) {
                handleCartAdd();
            } else {
                setOpenContinueAfterAddCart(true);
            }

        }

    }

    const continueShoppingAfterContinueAfterAddCart = () => {
        setOpenContinueAfterAddCart(false);
        handleCartAdd();
    }

    // ------- Continue After Add Cart END --------

    const fetchPreCalulatedPrice = async () => {

        const [prepared, sendData] = preparePrCalculationAndAddToCartData();

        if (!prepared) {
            return;
        }

        setPriceIsLoading(true)
        const [success, response] = await API.calcPrice(sendData);
        if (success) {
            const data = response.data;

            if (selectedDates === null || selectedDates === undefined) {
                setPreCalculatedPrice("0,00")
            }
            if (data.price !== null || data.price !== undefined || isNaN(data.price)) {
                let pstr = ''
                if (data.price_prefix !== undefined && data.price_prefix !== null) {
                    pstr = data.price_prefix + " " + data.price;
                } else {
                    pstr = data.price;
                }
                setPreCalculatedPrice(pstr);
            }
        } else {
            if (response.data.context.code !== 10019) {
                showError(response.msg);
                setPreCalculatedPrice(t("simple-assistant.errors.price-calculation.cannot-calculate-price"));
            } else {
                const t_key = "simple-assistant.errors.price-calculation." + response.data.message;
                setPreCalculatedPrice(t(t_key));
            }
        }
    };

    const preparePrCalculationAndAddToCartData = () => {
        let productId = selectedProductId;
        let datesSelected = {}

        // console.log(itemData, 'itemData')

        let data = {
            "product_id": productId,
            "person_id": activePerson,
        };

        // console.log(data, 'prepared Cart')

        if (selectedProductId === null || selectedProductId === '') {
            setPreCalculatedPrice(t("simple-assistant.errors.price-calculation.cannot-calculate-price"));
            return [false, null];
        }

        if (selectedDates === null || selectedDates === undefined) {
            datesSelected = {}
        } else {
            datesSelected = selectedDates;
        }

        if (datesSelected.start === undefined && datesSelected.end === undefined) {
            data = {
                "product_id": productId,
                "person_id": activePerson,
            };
        } else {
            data = {
                "product_id": productId,
                "person_id": activePerson,
                "selection": datesSelected
            }
        }

        data["category_id"] = selectedChild.id;

        if (crossSellingSelected !== null && crossSellingSelected.length > 0) {
            data.cross_selling = crossSellingSelected;
        }

        if (additionalProducts !== null && additionalProducts.length > 0) {
            let newAdditionalProducts = [];
            additionalProducts.map((item) => {
                if (item.selected) {
                    newAdditionalProducts.push({
                        'id': item.id,
                        'selected': item.selected
                    });
                }
            });

            data.additional_products = newAdditionalProducts;
        }
        
        if (selectedAdditionalPersons !== null && selectedAdditionalPersons.length > 0) {
            let newSelectedAdditionalPersons = [];
            selectedAdditionalPersons.map((person) => {
                newSelectedAdditionalPersons.push(person)
            });
            data.additional_persons = newSelectedAdditionalPersons;
        }

        // console.log(data, "DATA");

        if (data.person_id === null) {
            data.person_id = activePerson;
        }

        return [true, data];
    }

    const fetchAndSetPreCalculatedPrice = async () => {
        const priceField = document.getElementById("sb-product-card-price-value");
        if (priceField === undefined) {
            return;
        }

        await fetchPreCalulatedPrice().then(() => {
            setPriceIsLoading(false);
        });
    }

    const checkForTimeSelection = async () => {
        setTimeSelectionLoading(false);
        setTimeSelection(null);
        setSelectedTimes([]);
        if (selectedDates === null || selectedDates === undefined) {
            setUseAdditionalPersons(false);
            setTimeSelection(null);
            setSelectedTimes([]);
            return;
        } else {
            if (selectedDates.start === undefined || selectedDates.end === undefined) {
                return;
            }
        }

        const {start, end} = selectedDates;

        let productId = selectedProductId;
        if (start === undefined || end === undefined) {
            return;
        }
        if (productId === null || productId === '' || productId === undefined) {
            return;
        }
        let selection = 'get-times';

        let data = {
            'product_id': productId,
            'selection': selection,
            'start_date': start,
            'end_date': end
        }

        setTimeSelectionLoading(true);
        const [success, response] = await API.makeProductCheck(data);

        if (success) {
            const resp_data = response.data;
            const time_data = resp_data.times;
            const useTimes = resp_data.use_times;

            if (useTimes !== undefined && useTimes !== null && useTimes === false) {
                setTimeSelection(null);
            } else {

                if (time_data.resources === undefined && time_data.resources === null) {
                    return;
                }

                let newSelectedTimes = [];
                let newTimeSelection = time_data.resources;
                setTimeSelection(newTimeSelection);

                time_data.resources.map((item) => {
                    newSelectedTimes.push({
                        date: item.date,
                        time_id: ""
                    })
                });

                setSelectedTimes(newSelectedTimes);
            }

            if (resp_data.use_additional_persons !== undefined && resp_data.use_additional_persons !== null && resp_data.use_additional_persons === true) {
                // show additional Person Selection
                setUseAdditionalPersons(true);
            } else {
                setUseAdditionalPersons(false);
            }

        } else {
            // console.log(response, "GET_TIMES");
        }
        setTimeSelectionLoading(false);
    }

    const handleTimeSelect = ({time_id, date}) => {
        if (time_id === null || time_id === undefined) {
            return;
        }

        if (date === null || date === undefined) {
            return;
        }


        setSelectedTimes(prevSelectedTimes => {
            return prevSelectedTimes.map((item) => {
                if (item.date === date) {
                    return {
                        date: item.date,
                        time_id: time_id
                    }
                }
                return item;
            })
        });

    }

    React.useEffect(() => {
        // console.log(selectedAdditionalPersons, "SELECTED ADDITIONAL PERSONS");
        if (selectedDates !== null) {
            fetchAndSetPreCalculatedPrice();
        }
    }, [selectedDates, crossSellingSelected, additionalProducts, selectedAdditionalPersons]);

    React.useEffect(() => {
        checkForTimeSelection();
    }, [selectedDates]);

    // ------- Pre Price END -------

    const fetchContentData = async (productId = null) => {
        setApiLoading(true);


        if (productId === null && selectedProductId !== null) {
            productId = selectedProductId;
        }

        if (productId === null || productId === '') {
            setApiLoading(false);
            return;
        }

        const [success, response] = await API.getProduct(productId, activePerson);

        if (success) {
            setContentData(response.data);
            initCrossSellingSelection(response.data);
            initAdditionalProducts(response.data);
            initDisciplines(response.data);
        } else {
            showError(response)
        }
        setApiLoading(false);
    }

    const setFirstItemSelected = () => {
        if (products !== undefined && products !== null) {
            try {
                setSelectedProductId(products.bookable[0].id);
            } catch (e) {
                setSelectedProductId('');
            }
        } else {
            setSelectedProductId('');
        }
    }

    const initDisciplines = (d) => {
        if (d !== null && d !== undefined) {
            if (d.product !== null && d.product !== undefined) {
                if (d.product.disciplines !== null && d.product.disciplines !== undefined) {
                    let newDisciplines = [];
                    d.product.disciplines.map((item) => {
                        newDisciplines.push(item);
                    });
                    setDisciplines(newDisciplines);
                } else {
                    setDisciplines(null);
                }
            } else {
                setDisciplines(null);
            }
        } else {
            setDisciplines(null);
        }
    }

    const initCrossSellingSelection = (cD) => {
        if (cD !== null && cD !== undefined) {
            if (cD.product !== null && cD.product !== undefined) {
                if (cD.product['cross-selling'] !== null && cD.product['cross-selling'] !== undefined) {
                    let newCrossSellingSelected = [];
                    cD.product['cross-selling'].map((item) => {
                        newCrossSellingSelected.push({
                            id: item.product_id,
                            selected: false,
                            loading: false,
                            fields: null,
                            additional_products: null,
                            data: null,
                        });
                    });
                    setCrossSellingSelected(newCrossSellingSelected);
                }
            }
        }
    }

    const initAdditionalProducts = (aP) => {
        if (aP !== null && aP !== undefined) {
            if (aP.product !== null && aP.product !== undefined) {
                if (aP.product['additional-products'] !== null && aP.product['additional-products'] !== undefined) {
                    let newAdditionalProducts = [];
                    aP.product['additional-products'].map((item) => {
                        newAdditionalProducts.push({
                            always_selected: item.always_selected,
                            id: item.id,
                            name: item.name,
                            description: item.description,
                            selected: (item.always_selected !== undefined && item.always_selected !== null && item.always_selected === true) ? true : false
                        });
                    }, []);
                    setAdditionalProducts(newAdditionalProducts);
                }
            }
        }
    }

    const handleAdditionalProductSelect = (e) => {
        let id = e.target.value;
        let isSelected = e.target.checked;

        setAdditionalProducts(prevAdditionalProducts =>
            prevAdditionalProducts.map((item) => {
                if (item.id === id) {
                    return {
                        id: item.id,
                        name: item.name,
                        description: item.description,
                        selected: isSelected
                    }
                }
                return item;
            })
        );

        if (additionalProductsContinueAfterAddCart !== null && additionalProductsContinueAfterAddCart.length > 0) {
            setAdditionalProductsContinueAfterAddCart(prevAdditionalProducts =>
                prevAdditionalProducts.map((item) => {
                    if (item.id === id) {
                        return {
                            id: item.id,
                            name: item.name,
                            description: item.description,
                            selected: isSelected
                        }
                    }
                    return item;
                })
            );
        }
    }

    const handleCrossSellingSelect = async (e) => {
        let id = e.target.value;
        let isSelected = e.target.checked;

        try {
            id = parseInt(id);
        } catch (e) {
            return;
        }

        // make here productCheck as well but with selection 'cross-selling'
        if (selectedDates === null || selectedDates === undefined || (selectedDates.start === undefined || selectedDates.end === undefined)) {
            showError(t("simple-assistant.errors.price-calculation.no-dates-selected"));
            return;
        }

        const {start, end} = selectedDates;

        let selection = 'cross-selling';

        let data = {
            'product_id': id,
            'selection': selection,
            'start_date': start,
            'end_date': end
        }

        setCrossSellingSelected(prevCrossSellingSelected =>
            prevCrossSellingSelected.map((item) => {
                if (item.id === id) {
                    let loading = (isSelected);
                    return {
                        id: item.id,
                        selected: isSelected,
                        loading: loading,
                        fields: null,
                        additional_products: null,
                        data: null,
                    }
                }
                return item;
            })
        );

        if (!isSelected) {
            return;
        }

        const [success, response] = await API.makeProductCheck(data);
        if (success) {
            // console.log(response, "CROSS SELLING");
            const _data = response.data
            const _additional_products = _data.additional_products;
            const _fields = _data.fields;
            const _prov_data_set = _data.provided_data_set;
            setCrossSellingSelected(prevCrossSellingSelected =>
                prevCrossSellingSelected.map((item) => {
                    if (item.id === id) {
                        return {
                            id: item.id,
                            selected: isSelected,
                            loading: false,
                            fields: (_fields !== null && _fields !== undefined) ? _fields : null,
                            additional_products: (_additional_products !== null && _additional_products !== undefined) ? _additional_products : null,
                            data: (_prov_data_set !== null && _prov_data_set !== undefined) ? _prov_data_set : null,
                        }
                    }
                    return item;
                })
            );

        } else {
            // console.log('error', response);
            setCrossSellingSelected(prevCrossSellingSelected =>
                prevCrossSellingSelected.map((item) => {
                    if (item.id === id) {
                        return {
                            id: item.id,
                            selected: isSelected,
                            loading: false,
                            fields: null,
                            additional_products: null,
                            data: null,
                        }
                    }
                    return item;
                })
            );
        }

    }

    const handlePickupLocationSelect = (e) => {
        setSelectedPickupLocation(e.target.value);
    }

    const handleSelectProduct = (e) => {
        setSelectedProductId(e.target.value);
    }

    const fetchTemplate = async () => {
        const _template = await LoadTemplateFile(true);
        setTemplate(_template);
    }

    React.useEffect(() => {
        setSelectedProductId('');
        setFirstItemSelected();
    }, [products]);

    React.useEffect(() => {
        if (selectedProductId !== null) {
            fetchContentData(selectedProductId);
        }
    }, [selectedProductId]);

    React.useEffect(() => {
        let newBoxSettings = {...initBoxSettings};

        if (viewType === 'mobile' || viewType === 'tablet') {
            newBoxSettings.flexDirection = 'column';
            newBoxSettings.gap = '10px';
        }

        setBoxSettings(newBoxSettings);
    }, [viewType]);

    React.useEffect( () => {
        fetchTemplate();
        putSelectedDates();
    }, [])

    React.useEffect(() => {
        if (contentData !== null && contentData !== undefined) {
            if (contentData.product !== null && contentData.product !== undefined) {
                if (contentData.product.locations !== null && contentData.product.locations !== undefined) {
                    if (contentData.product.locations.length > 0 && contentData.product.locations.length === 1) {
                        setSelectedPickupLocation(contentData.product.locations[0].pickup_location_id);
                    }
                }
            }
        }
    }, [contentData])

    if (selectedProductId === '' || selectedProductId === null) {
        return (
            <Box>
                {/*<ProductPlaceholder />*/}
            </Box>
        )
    }

    return (
        <Box
            sx={boxSettings}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    maxWidth: '300px',
                    minWidth: '350px',
                }}
            >
                <FormControl sx={{ minWidth: '100%', width: '100%', maxWidth: '200px' }}>
                    <InputLabel id="demo-simple-select-helper-label">Auswahl</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        label="Auswahl"
                        onChange={handleSelectProduct}
                        value={selectedProductId}
                        variant={"outlined"}
                    >
                        {
                            (
                                (products !== null && products !== undefined)
                                &&
                                (products.bookable !== null && products.bookable !== undefined)
                            )
                            ? (
                                    (products.bookable.length > 0)
                                        ?
                                        (
                                            products.bookable.map((product) => {
                                                let value = product.id;
                                                let name = product.name[currentLanguage];
                                                return (
                                                    <MenuItem value={value} key={'sk-product-select-' + value}>{name}</MenuItem>
                                                )
                                            })
                                        )
                                        :
                                        <MenuItem value={100}>name</MenuItem>
                                )
                                : <MenuItem></MenuItem>
                        }
                    </Select>
                </FormControl>
                {
                    (contentData === null || apiLoading)
                    ?
                    <Box>
                        Loading...
                    </Box>
                    :
                        <>
                            <ProductCalendar calendarBooking={contentData['product']['calendar-booking']} isMobile={isMobile}/>
                        </>
                }
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                }}
            >
                {
                    (contentData !== null &&
                        contentData.product !== undefined &&
                        contentData.product.img !== undefined &&
                        (contentData.product.img.src !== null || contentData.product.img.src !== '')
                    )
                    ?
                        <img src={contentData.product.img.src} alt={contentData.product.img.alt} style={{maxWidth: "400px", maxHeight: "400px", objectFit: (contentData.product.img["object-fit"]) ? contentData.product.img["object-fit"] : "contain"}}/>
                        :
                        <></>
                }
                {
                    (selectedChild !== null &&
                        selectedChild !== undefined &&
                        (selectedChild.description !== undefined ||
                            selectedChild.description !== null)
                    )
                        ? <p dangerouslySetInnerHTML={{__html: selectedChild.description[currentLanguage]}}></p>
                        : <></>
                }
                {
                    (contentData !== null &&
                        contentData.product !== undefined &&
                        (contentData.product[currentLanguage] !== undefined ||
                            contentData.product[currentLanguage].description !== null)
                    )
                        ? <p dangerouslySetInnerHTML={{__html: contentData.product[currentLanguage].description}}></p>
                        : <></>
                }

                <PrivateCourseAdditionalPersonSelection
                    selectedAdditionalPersons={selectedAdditionalPersons}
                    useAdditionalPersons={useAdditionalPersons}
                    setSelectedAdditionalPersons={setSelectedAdditionalPersons}
                    selectedDates={selectedDates}
                    fetchAndSetPreCalculatedPrice={fetchAndSetPreCalculatedPrice}
                />

                <PrivateCourseTimeSelection
                    handleTimeSelect={handleTimeSelect}
                    selectedTimes={selectedTimes}
                    timeSelection={timeSelection}
                    timeSelectionLoading={timeSelectionLoading}
                />

                <Disciplines
                    disciplines={disciplines}
                    selectedDisciplines={selectedDisciplines}
                    setSelectedDisciplines={setSelectedDisciplines}
                />

                <RequiredFields
                    requiredFields={requiredFields}
                    setRequiredFields={setRequiredFields}
                    requiredFieldsData={requiredFieldsData}
                    setRequiredFieldsData={setRequiredFieldsData}
                    productData={contentData}
                />

                {
                    (contentData !== null &&
                    contentData.product !== undefined &&
                    (contentData.product['cross-selling'] !== undefined &&
                    contentData.product['cross-selling'].length > 0)
                    && crossSellingSelected.length > 0)
                        ? (
                            <CrossSellingProduct
                                contentData={contentData}
                                crossSellingSelected={crossSellingSelected}
                                handleCrossSellingSelect={handleCrossSellingSelect}
                                setCrossSellingSelected={setCrossSellingSelected}
                            />
                        ) : <></>
                }

                {
                    (additionalProducts !== null && additionalProducts.length > 0)
                    ? (
                        <Box
                            sx={{
                                p: 1
                            }}
                            >
                            {
                                additionalProducts.map((item) => {
                                    return (
                                        <Box
                                            key={'additional-' + item.id}
                                        >
                                            <FormControlLabel
                                                control={<Checkbox/>}
                                                label={item.name}
                                                id={'additional-' + item.id}
                                                name={'additional-' + item.id}
                                                value={item.id}
                                                onChange={handleAdditionalProductSelect}
                                                checked={item.selected || false}
                                                disabled={item.always_selected || false}
                                            />
                                            <FormHelperText>{item.description}</FormHelperText>
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                        )
                    : <></>
                }
                {
                    (contentData !== null &&
                        contentData.product !== undefined &&
                        (contentData.product.locations !== undefined &&
                        contentData.product.locations.length !== null &&
                        contentData.product.locations.length > 0)
                    )
                    ?
                        <FormControl sx={{ minWidth: '100%', width: '100%', maxWidth: '200px', my: 2 }}>
                            <InputLabel id="demo-simple-select-helper-label">{
                                (contentData.product.information !== null && contentData.product.information !== undefined)
                                ? (contentData.product.information.pt !== undefined && contentData.product.information.pt === 0 || contentData.product.information.pt === "0")
                                    ? 'Kursort'
                                    : 'Abholort'
                                : 'Abholort'
                            }</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                label="Auswahl"
                                value={selectedPickupLocation}
                                onChange={handlePickupLocationSelect}
                            >
                                {
                                    contentData.product.locations.map((location) => {
                                        const name = `${location.name} / ${location.address} / ${location.plz} ${location.city}`;
                                        return (
                                            <MenuItem
                                                value={location.pickup_location_id}
                                                key={'sb-product-card-location-' + location.pickup_location_id}
                                            >
                                                {name}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                        :
                        <></>
                }

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        borderRadius: '5px',
                        backgroundColor: '#f5f5f5',
                        border: '1px solid #dedede',
                        p: 2,
                        my: 1
                    }}
                >
                    <Typography
                        variant="body1"
                        color="text.primary"
                        id={"sb-product-card-amount-days-value"}
                    >
                        -
                    </Typography>
                    <Typography
                        variant="body1"
                        id={"sb-product-card-price-value"}
                        noWrap={true}
                    >
                        {
                            (priceIsLoading)
                                ? <CircularProgress size={"1rem"}/>
                                : preCalculatedPrice
                        }
                    </Typography>
                </Box>

                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        maxWidth: '20rem',
                        alignSelf: 'flex-end',
                    }}
                    onClick={productAddToCart}
                >
                    {t('simple-assistant.addToCart')}
                </Button>
            </Box>

            <ContinueAfterAddCart
                open={openContinueAfterAddCart}
                addToCart={handleCartAdd}
                close={() => setOpenContinueAfterAddCart(false)}
                t={t}
                additionalProducts={additionalProductsContinueAfterAddCart}
                crossSellingItems={crossSellingContinueAfterAddCart}
                isMobile={isMobile}
                handleAdditionalProductSelect={handleAdditionalProductSelect}
                handleCrossSellingSelect={handleCrossSellingSelect}
                crossSellingSelected={crossSellingSelected}
            />

        </Box>
    );
}

export default ProductSelect;