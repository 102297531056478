import React, {useEffect, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CardActions,
    CircularProgress
} from "@mui/material";
import {usePersonContext} from "./globals/PersonContext";
import {useMobileViewContext} from "./globals/MobileViewContext";
import InputField from "../../../common/components/inputFields/InputField";
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";


const initBoxSx = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '10%',
    pt: 2,
    '& button': {
        width: '100%',
    }
}

function PersonComponent(props) {

    const [boxSx, setBoxSx] = useState(initBoxSx);
    const [persons, setPersons] = useState(props.persons);

    const [volPersonForm, setVolPersonForm] = useState(null);
    const [volPersonFormData, setVolPersonFormData] = useState(null);

    const {activePerson, selectActivePerson, voluntaryPersonForm, voluntaryPersonFormData,
        personFormData, personForm, voluntaryLoading, updatePerson, _getPersonsFromAPI} = usePersonContext();

    const {viewType} = useMobileViewContext();

    const {t} = useTranslation();

    useEffect(() => {
        setPersons(props.persons);
    }, [props.persons]);

    useEffect(() => {
        let newBoxSx = {...initBoxSx};

        if (viewType === 'mobile' || viewType === 'tablet') {
            newBoxSx.width = '80%';
            newBoxSx.flexDirection = 'column';
        }

        setBoxSx(newBoxSx);
    }, [viewType]);

    useEffect(() => {
        // console.log(persons)

        let newPersonForm = [];
        let newPersonFormData = {};

        if (personForm !== null && personForm !== undefined) {
            Object.keys(personForm).forEach((key) => {
                newPersonForm.push(personForm[key]);
            });
        }

        if (personFormData !== null && personFormData !== undefined) {
            Object.keys(personFormData).forEach((key) => {
                newPersonFormData[key] = '';
            });
        }

        if (voluntaryPersonForm !== null && voluntaryPersonForm !== undefined) {
            voluntaryPersonForm.forEach((field) => {
                newPersonForm.push(field);
            });
        }

        if (voluntaryPersonFormData !== null && voluntaryPersonFormData !== undefined) {
            Object.keys(voluntaryPersonFormData).forEach((key) => {
                newPersonFormData[key] = voluntaryPersonFormData[key];
            });
        }

        persons.map((person) => {
            if (person.id === activePerson) {
                Object.keys(person).forEach((key) => {
                    newPersonFormData[key] = person[key];
                });
            }
        })

        setVolPersonForm(newPersonForm);
        setVolPersonFormData(newPersonFormData);
    }, [voluntaryPersonFormData, voluntaryPersonForm, personForm, personFormData, persons, activePerson]);

    const handlePersonClick = (e) => {
        // e.target.value is the person's id
        selectActivePerson(e.target.value);
    }

    const handleVolPersonFormChange = (e) => {
        // e.target.value is the person's id
        setVolPersonFormData({
            ...volPersonFormData,
            [e.target.name]: e.target.value
        });
    }

    const handleVolPersonSave = async () => {
        // console.log(volPersonFormData);
        // TODO: call api to update person data
        await updatePerson(volPersonFormData, true);
        await _getPersonsFromAPI();
    }

    const handleVolPersonCancel = () => {
        if (voluntaryPersonFormData !== null && voluntaryPersonFormData !== undefined) {
            Object.keys(voluntaryPersonFormData).forEach((vkey) => {
                persons.map((person) => {
                    if (person.id === activePerson) {
                        Object.keys(person).forEach((key) => {
                            setVolPersonFormData({
                                ...volPersonFormData,
                                [vkey]: person[vkey]
                            })
                        });
                    }
                })
            });
        }
    }

    return (
        <Box
            id={'simple-assistant-persons'}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
            }}
        >
            <Box
                sx={boxSx}
            >
            {
                persons.map((person, index) => {
                    let variant = 'outlined';
                    let disabled = false;
                    if (activePerson !== null && String(activePerson) === String(person.id)) {
                        variant = 'contained';
                        disabled = true;
                    }

                    return (
                        <Button
                            key={index}
                            variant={variant}
                            sx={{
                                width: '100%',
                                margin: '5px'
                            }}
                            value={person.id}
                            onClick={handlePersonClick}
                            disabled={disabled}
                        >
                            {index + 1}. {person.fname} {person.sname}
                        </Button>
                    );
                })
            }
            </Box>
            {
                (voluntaryLoading) ? (<CircularProgress />) : (<></>)
            }
                {
                    (props.template !== null && props.template !== undefined && props.template.personAdding !== undefined && props.template.personAdding.display && persons !== null && persons.length > 0) ?
                        (
                    <Accordion
                            sx={{
                            mt: 2,
                            mb: 1,
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            sx={{
                                transition: '0.3s',
                                backgroundColor: '#f9f9f9',
                                '&.Mui-expanded': {
                                    borderBottom: '1px solid #f0f0f0',
                                },
                                ':hover': {
                                    cursor: 'pointer',
                                    backgroundColor: '#f0f0f0'
                                }
                            }}
                        >
                            <Typography variant={'body1'}>{t('simple-assistant.additional-person-data')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'start',
                                    gap: 2,
                                    alignItems: 'center',
                                    width: '100%',
                                    flexWrap: 'wrap'
                                }}
                            >
                                {
                                    (volPersonForm !== null && volPersonForm !== undefined) ?
                                        (
                                            volPersonForm.map((field, index) => {
                                                return (
                                                    <InputField
                                                        key={index}
                                                        field={field}
                                                        formData={volPersonFormData}
                                                        setFormData={setVolPersonFormData}
                                                        t={t}
                                                        value={volPersonFormData[field.name]}
                                                        handleChange={handleVolPersonFormChange}
                                                        currentLanguage={props.currentLanguage}
                                                    />
                                                );
                                            })
                                        ) :
                                        (<></>)
                                }
                            </Box>
                        </AccordionDetails>
                        <CardActions
                            sx={{
                                justifyContent: 'space-between',
                            }}
                        >
                            <Button
                                variant="text"
                                onClick={handleVolPersonCancel}
                            >
                                {t('person.create.cancel')}
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleVolPersonSave}
                                sx={{
                                    backgroundColor: '#59b65e',
                                    color: '#fff',
                                    '&:hover': {
                                        backgroundColor: '#35873b',
                                    }
                                }}
                            >
                                {t('person.create.save')}
                            </Button>
                        </CardActions>
                    </Accordion>

                    ) : <></>
                }
        </Box>
    );
}

export default PersonComponent;