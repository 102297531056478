import React from 'react';
import {Box, Button} from "@mui/material";
import CartAPI from "../../../../../../common/handlers/api-handlers/CartAPI";


function CustomerCanAcceptReject({t, checkNewRequestState, process}) {

    const cartApi = new CartAPI();

    const handleBtnClick = async (action) => {
        // console.log(action)

        const [success, response] = await cartApi.sendRequestAction({
            'action': 'customer_request_action',
            'process_id': process.id,
            'data': {
                'do': action
            }
        });

        if (success) {
            checkNewRequestState(process.id);
        } else {
            // console.log(response)
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                mt: 3,
                mb: 3,
                width: '100%',
                gap: 5
            }}
        >
            <Button
                variant={"contained"}
                sx={{
                    width: '100%',
                    backgroundColor: '#a3a3a3',
                    '&:hover': {
                        backgroundColor: '#656565'
                    }
                }}
                onClick={() => handleBtnClick('reject')}
            >
                {t('cart.request.reject')}
            </Button>

            <Button
                variant={"contained"}

                sx={{
                    width: '100%',
                    backgroundColor: '#4c9549',
                    '&:hover': {
                        backgroundColor: 'green'
                    }
                }}
                onClick={() => handleBtnClick('accept')}
            >
                {t('cart.request.accept')}
            </Button>
        </Box>
    );
}


function RequestInteractions({t, activeState, checkNewRequestState, process}) {

    if (activeState === undefined || activeState === null) {
        return (
            <></>
        )
    }

    if (activeState === 'created' || activeState === 'sent' || activeState === 'processing' || activeState === 'accepted_by_customer') {
        return (
            <></>
        )
    } else if (activeState === 'accepted_by_admin' || activeState === 'edited') {
        return (
            <CustomerCanAcceptReject t={t} checkNewRequestState={checkNewRequestState} process={process}/>
        )
    } else if (activeState === 'rejected_by_admin' || activeState === 'rejected_by_customer') {
        return (
            <></>
        )
    } else if (activeState === 'done' || activeState === 'automatic_done') {
        return (
            <></>
        )
    } else {
        return (
            <></>
        )
    }
}

export default RequestInteractions;